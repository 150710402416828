import { faSignalStreamSlash } from '@awesome.me/kit-29dd63b8b5/icons/classic/solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Badge } from '@mantine/core'
import { useNetworkStatus } from '@sup/capacitor/hooks/use_network_status'
import cn from 'clsx'

export const NetworkStatusMonitor = () => {
	const { connected } = useNetworkStatus()
	if (connected) return null
	return (
		<div
			className={cn('fixed inset-0 z-50 w-screen', {
				'h-full': !connected,
				'h-0': connected,
			})}
		>
			<div
				className={cn(
					'h-full w-full overflow-hidden bg-black/25 backdrop-blur-[2px] transition-all delay-75 duration-200 ease-in-out',
					{
						'opacity-100': !connected,
						'opacity-0': connected,
					},
				)}
			>
				<div
					className={cn('flex h-full flex-col items-center justify-end delay-100', {
						'opacity-0': connected,
						'opacity-100': !connected,
					})}
				>
					<Badge variant="filled" color="red" size="lg" className="mb-8">
						<div className="flex items-center">
							<FontAwesomeIcon icon={faSignalStreamSlash} className="mr-2 w-5" />
							Network unavailable
						</div>
					</Badge>
				</div>
			</div>
		</div>
	)
}
