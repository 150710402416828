import { useEffect, useState } from 'react'

import type { ConnectionStatus } from '@capacitor/network'
import { Network } from '@capacitor/network'

type UseStatusResult = ConnectionStatus

export function useNetworkStatus(): UseStatusResult {
	const [status, setStatus] = useState<ConnectionStatus>()

	useEffect(() => {
		Network.getStatus().then(setStatus)
		const listener = Network.addListener('networkStatusChange', (status: ConnectionStatus) => {
			setStatus(status)
		})

		return () => {
			listener
				.then((l) => l.remove())
				.catch((e) => console.error('Failed to remove network listener', e))
		}
	}, [])

	return {
		connected: status?.connected ?? true,
		connectionType: status?.connectionType ?? 'unknown',
	}
}
