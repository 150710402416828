import { config as faConfig } from '@fortawesome/fontawesome-svg-core'
import faStyles from '@fortawesome/fontawesome-svg-core/styles.css?url'
import carouselStyles from '@mantine/carousel/styles.css?url'
import { ColorSchemeScript, MantineProvider, mantineHtmlProps } from '@mantine/core'
import mantineStyles from '@mantine/core/styles.css?url'
import mantineDateStyles from '@mantine/dates/styles.css?url'
import { ModalsProvider } from '@mantine/modals'
import nprogressStyles from '@mantine/nprogress/styles.css?url'
import { captureException } from '@sentry/react'
import { useNetworkStatus } from '@sup/capacitor/hooks/use_network_status'
import { GlobalProgressBar } from '@sup/cr/components/GlobalProgressBar'
import { config } from '@sup/cr/config.server'
import { AppUrlListener, HideSplashScreen, NetworkStatusMonitor } from '@sup/cr/native-components'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import cn from 'clsx'
import { type PropsWithChildren, useEffect } from 'react'
import cropCss from 'react-image-crop/dist/ReactCrop.css?url'
import type { LinksFunction, LoaderFunctionArgs, MetaFunction } from 'react-router'
import { Links, Meta, Outlet, Scripts, ScrollRestoration, useMatches } from 'react-router'
import { useRouteError } from 'react-router'
import { isRouteErrorResponse } from 'react-router'
import { ClientOnly } from 'remix-utils/client-only'
import { Toaster } from 'sonner'
import sonnerStyles from 'sonner/dist/styles.css?url'
import chatCss from 'stream-chat-react/dist/css/index.css?url'
import ErrorPage from './shared/components/ErrorPage'
import { trpc } from './shared/trpc'
import styles from './tailwind.css?url'
import { theme } from './theme'
faConfig.autoAddCss = false

export const meta: MetaFunction = () => [{ title: 'Sup' }]

export const links: LinksFunction = () => [
	{ rel: 'manifest', href: '/manifest.webmanifest' },
	{ rel: 'apple-touch-icon', href: '/app_icons/icon_apple_touch.png' },
	{ rel: 'icon', type: 'image/x-icon', href: '/favicon.png' },
	{ rel: 'stylesheet', href: chatCss },
	{ rel: 'stylesheet', href: styles },
	{ rel: 'stylesheet', href: mantineStyles },
	{ rel: 'stylesheet', href: mantineDateStyles },
	{ rel: 'stylesheet', href: nprogressStyles },
	{ rel: 'stylesheet', href: carouselStyles },
	{ rel: 'stylesheet', href: cropCss },
	{ rel: 'stylesheet', href: faStyles },
	{ rel: 'stylesheet', href: sonnerStyles },
]

export const loader = async ({ context }: LoaderFunctionArgs) => {
	const app = await context.make('app')

	return {
		ENV: {
			SENTRY_DSN: config.sentry.dsn,
			environment: app.config.get<string>('app.appEnv'),
			SENTRY_ENABLED: process.env.SENTRY_ENABLED === 'true',
			POSTHOG_API_KEY: app.config.get<string>('posthog.apiKey'),
		},
		supabase: {
			url: app.config.get<string>('supabase.url'),
			anonKey: app.config.get<string>('supabase.anonKey'),
		},
	}
}

export function Layout({ children }: PropsWithChildren) {
	const network = useNetworkStatus()

	return (
		<html lang="en" {...mantineHtmlProps}>
			<head>
				<meta charSet="utf-8" />
				<meta
					name="viewport"
					content="width=device-width,initial-scale=1,maximum-scale=1,viewport-fit=cover"
				/>
				<ColorSchemeScript />
				<Meta />
				<Links />
				<WriteBrowserEnv />
			</head>

			<body className={cn('h-screen', { 'no-scroll': !network.connected })}>
				<MantineProvider theme={theme}>
					<ModalsProvider>
						<ClientOnly>
							{() => (
								<>
									<NetworkStatusMonitor />
									<HideSplashScreen />
									<AppUrlListener />
								</>
							)}
						</ClientOnly>
						<GlobalProgressBar />
						{children}
					</ModalsProvider>
				</MantineProvider>
				<Toaster richColors duration={5000} position="bottom-right" />
				<ScrollRestoration
					getKey={(location) => {
						return location.pathname
					}}
				/>
				<Scripts />
			</body>
		</html>
	)
}

function App() {
	return (
		<>
			<ReactQueryDevtools position="bottom-right" />
			<Outlet />
		</>
	)
}

export function ErrorBoundary() {
	const error = useRouteError()

	useEffect(() => {
		captureException(error)
	}, [error])

	return isRouteErrorResponse(error) ? (
		<ErrorPage
			statusCode={error.status}
			title={error.statusText}
			description={error.data}
			redirect="/"
			buttonText="Visit the homepage"
		/>
	) : error instanceof Error ? (
		<ErrorPage
			title={error.message}
			stack={error.stack}
			redirect="/"
			buttonText="Visit the homepage"
		/>
	) : (
		<ErrorPage title="Unknown Error" redirect="/" buttonText="Visit the homepage" />
	)
}

export default trpc.withTRPC(App)

function WriteBrowserEnv() {
	const matches = useMatches()
	const ENV = matches.find((match) => match.id === 'root')?.data?.ENV ?? {}
	return (
		<script
			// biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
			dangerouslySetInnerHTML={{
				__html: `window.ENV = ${JSON.stringify(ENV)};
		if(window.process == null) {
			window.process = {
				env: '${ENV?.environment}'
			}
		}`,
			}}
		/>
	)
}
