import { useNavigate } from 'react-router'

import { App as CapApp } from '@capacitor/app'
import { Browser } from '@capacitor/browser'

import { useEffect } from 'react'

export const AppUrlListener = () => {
	const navigate = useNavigate()

	useEffect(() => {
		CapApp.addListener('appUrlOpen', async ({ url }) => {
			console.log('App opened with URL', url)

			if (url === 'co.sup.app.creators://close-in-app-browser') {
				Browser.close()
					.then(() => console.log('Closed in app browser'))
					.catch((e) => console.error('Failed to close in app browser', e))
				return
			}

			const parsed = new URL(url)

			navigate(parsed.pathname + parsed.search)
		})
	}, [])

	return null
}
