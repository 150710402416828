import { useNetworkStatus } from '@sup/capacitor/hooks/use_network_status'

import { Capacitor } from '@capacitor/core'
import { SplashScreen } from '@capacitor/splash-screen'
import { useAsync } from 'react-use'

export const HideSplashScreen = () => {
	const { connected } = useNetworkStatus()

	useAsync(async () => {
		if (Capacitor.isPluginAvailable('SplashScreen')) {
			if (connected) {
				await SplashScreen.hide()
			}
		}
	}, [connected])
	return null
}
