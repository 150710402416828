import {
	Alert,
	Anchor,
	Tabs,
	type VariantColorsResolver,
	colorsTuple,
	createTheme,
	defaultVariantColorsResolver,
	parseThemeColor,
} from '@mantine/core'
import buttonClasses from './styles/button.module.css'
import tabsClasses from './styles/tabs.module.css'

const variantColorResolver: VariantColorsResolver = (input) => {
	const defaultResolvedColors = defaultVariantColorsResolver(input)
	const parsedColor = parseThemeColor({
		color: input.color || input.theme.primaryColor,
		theme: input.theme,
	})

	if (parsedColor.isThemeColor && input.variant === 'outline') {
		return { ...defaultResolvedColors, color: 'var(--mantine-color-theme-6)' }
	}

	if (parsedColor.isThemeColor && input.variant === 'filled') {
		return {
			...defaultResolvedColors,
			color: 'var(--mantine-color-theme-10)',
		}
	}

	if (parsedColor.isThemeColor && input.variant === 'subtle') {
		return {
			...defaultResolvedColors,
			color: 'var(--mantine-color-gray-6)',
		}
	}

	if (parsedColor.isThemeColor && input.variant === 'light') {
		return {
			...defaultResolvedColors,
			color: 'var(--mantine-color-theme-6)',
		}
	}

	if (parsedColor.isThemeColor && input.variant === 'white') {
		return {
			...defaultResolvedColors,
			color: 'var(--mantine-color-gray-6)',
		}
	}

	return defaultResolvedColors
}

export const theme = createTheme({
	variantColorResolver,
	colors: {
		theme: [
			'#fffbeb',
			'#fef4c7',
			'#fee989',
			'#fed74b',
			'#fdc831',
			'#f7a409',
			'#db7b04',
			'#b55608',
			'#93430d',
			'#79370e',
			'#461b02',
		],
		accent: [
			'#fef3f2',
			'#fee4e2',
			'#fdcecb',
			'#fbaba6',
			'#f77a72',
			'#ed5046',
			'#da3227',
			'#b7271e',
			'#98241c',
			'#7e241e',
			'#440f0b',
		],
		'off-white': colorsTuple('#F5F3EF'),
	},
	primaryColor: 'theme',
	primaryShade: 4,
	components: {
		Button: {
			defaultProps: { size: 'sm' },
			classNames: buttonClasses,
		},
		Anchor: Anchor.extend({
			defaultProps: {
				c: 'blue',
			},
		}),
		Drawer: {
			styles: { root: { zIndex: 100 } },
		},
		Avatar: {
			classNames: {
				placeholder: 'bg-transparent group hover:bg-gray-100',
				placeholderIcon: 'text-gray-400 group-hover:text-gray-500',
			},
		},
		Tabs: Tabs.extend({
			classNames: tabsClasses,
			// classNames: {
			// 	tab: 'data-[active]:font-bold',
			// 	tabLabel: 'py-1',
			// },
		}),
		Modal: {
			defaultProps: {
				style: { pointerEvents: 'auto' },
			},
		},
		Alert: Alert.extend({
			classNames: {
				root: 'group data-[variant=minimal]:!bg-transparent',
				icon: 'group-data-[variant=minimal]:mr-2 text-dimmed ',
				message: 'group-data-[variant=minimal]:text-sm',
			},
		}),
	},
})
