import { useEffect, useRef } from 'react'
import { useNavigation } from 'react-router'

import { NavigationProgress, nprogress } from '@mantine/nprogress'

export const GlobalProgressBar = () => {
	const navigation = useNavigation()
	const navigationRef = useRef<ReturnType<typeof useNavigation>>()
	const progressing = useRef<boolean>(false)

	navigationRef.current = navigation

	useEffect(() => {
		let timeout: NodeJS.Timeout
		const state = navigation.state
		if (state === 'idle' && progressing.current) {
			nprogress.complete()
			progressing.current = false
		} else {
			timeout = setTimeout(() => {
				if (navigationRef.current?.state !== 'idle') {
					nprogress.start()
					progressing.current = true
				}
			}, 125)
		}

		return () => timeout && clearTimeout(timeout)
	}, [navigation.state])

	return <NavigationProgress color="#FF91D5" />
}
